const DATE_FORMATS_MOMENT = [
  'DD-MM-YYYY'
  // We are currently forcing the DD-MM-YYYY format
  // 'MM-DD-YYYY',
  // 'YYYY-MM-DD',
  // 'DD/MM/YYYY',
  // 'MM/DD/YYYY',
  // 'YYYY/MM/DD'
];
const DATE_DD_MM_YYYY_PATTERN = /^(?<DAY>\d?\d)(?<SEPARATOR>[-/])(?<MONTH>\d\d)(\k<SEPARATOR>)(?<YEAR>\d{4})$/;
const DATE_MM_DD_YYYY_PATTERN = /^(?<MONTH>\d\d)(?<SEPARATOR>[-/])(?<DAY>\d?\d)(\k<SEPARATOR>)(?<YEAR>\d{4})$/;
const DATE_YYYY_MM_DD_PATTERN = /^(?<YEAR>\d{4})(?<SEPARATOR>[-/])(?<MONTH>\d\d)(\k<SEPARATOR>)(?<DAY>\d\d)$/;
const HOURS_AND_MINUTES_PATTERN = /^([01][0-9]|2[0-3]):([0-5][0-9])$/;
// eslint-disable-next-line
const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const LETTERS_ONLY_PATTERN = /[A-Za-z]/;
const NUMBERS_ONLY_PATTERN = /^[0-9]+$/i;

const PHONE_NUMBER_PATTERN = /^\d(\s?\d){8}$/;

// Pattern that only accepts letters, numbers and the special characters
// For now, we want to simplify the password required format to reduce user onboarding friction, so the only requirement is the min length
// const PASSWORD_PATTERN = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
const PASSWORD_PATTERN = /^.{8,}$/;

const POSTAL_CODE_PATTERN = /^\d{4}(-\d{3})?$/;

/* Minimum and max lengths */
const PASSWORD_MINIMUM_LENGTH = 8;
const PHONE_MAXIMUM_LENGTH = 15;
const PHONE_MINIMUM_LENGTH = 9;
const VAT_MINIMUM_LENGTH = 8;

export {
  DATE_DD_MM_YYYY_PATTERN,
  DATE_FORMATS_MOMENT,
  DATE_MM_DD_YYYY_PATTERN,
  DATE_YYYY_MM_DD_PATTERN,
  EMAIL_PATTERN,
  HOURS_AND_MINUTES_PATTERN,
  LETTERS_ONLY_PATTERN,
  NUMBERS_ONLY_PATTERN,
  PASSWORD_PATTERN,
  PHONE_NUMBER_PATTERN,
  POSTAL_CODE_PATTERN,
  PASSWORD_MINIMUM_LENGTH,
  PHONE_MAXIMUM_LENGTH,
  PHONE_MINIMUM_LENGTH,
  VAT_MINIMUM_LENGTH
};
