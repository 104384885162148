import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

// Utils
import bemify from 'utils/bemify';

// Styles
import stylesModule from './styles.module.scss';


const styles = bemify(stylesModule);

const ErrorBox = ({ className, link, message }) => (
  <div className={`${styles.errorBox}${className ? ` ${className}` : ''}`}>
    {message}
    {!!link && (
      <Link
        href={link.href}
        legacyBehavior
      >
        <a className={styles.errorBox__link}>
          {link.text}
        </a>
      </Link>
    )}
  </div>
);

ErrorBox.propTypes = {
  className: PropTypes.string,
  link: PropTypes.shape({
    href: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }),
  message: PropTypes.string.isRequired
};

ErrorBox.defaultProps = {
  className: null,
  link: null
};

export default ErrorBox;
