import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { Controller, useForm } from 'react-hook-form';
import useTranslationWithFallback from 'hooks/use-translation-with-fallback';
import { useGoogleLogin } from '@react-oauth/google';
import { useSearchParams } from 'next/navigation';

// Store
import { useDispatch, useSelector } from 'react-redux';
import {
  login,
  logoutUser,
  selectIsLoggingIn,
  selectToken
} from 'store/slices/userSlice';

// Utils
import bemify from 'utils/bemify';
import { EMAIL_PATTERN } from 'utils/form-validation';
import showCapsLockWarning from 'utils/show-caps-lock-message-on-warning-input';

// Context
import { useToastContext } from 'context/toast-context';

// Components
import Button from 'components/button';
// This component was used for the "Remember Password" input, that is not currently being used
// import Checkbox from 'components/checkbox';
import ErrorBox from 'components/error-box';
import Input from 'components/input';
import Loader from 'components/loader';

// Styles
import stylesModule from './styles.module.scss';


const styles = bemify(stylesModule);

const LoginForm = ({ className }) => {
  const { t } = useTranslationWithFallback('login');

  const dispatch = useDispatch();
  const router = useRouter();
  const searchParams = useSearchParams();

  const { showToast } = useToastContext();

  // Selectors
  const isLoggingIn = useSelector(selectIsLoggingIn);
  const storedToken = useSelector(selectToken);

  const [capsLockMessage, setCapsLockMessage] = useState(null);
  const [displayRecoverPasswordLink, setDisplayRecoverPasswordLink] = useState(false);
  const [inputType, setInputType] = useState('password');
  const [loginError, setLoginError] = useState(null);

  const { handleSubmit, formState: { errors }, control, reset, setFocus } = useForm({
    defaultValues: {
      checkbox: false,
      email: '',
      password: ''
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange'
  });

  const authenticationTokenFromQueryString = searchParams.get('token');

  // TODO: Create a component with the eye icon and this handling
  const handleInputIconClick = () => {
    if (inputType === 'password') setInputType('text');
    else setInputType('password');

    setFocus('password');
  };

  const handleInputKeyUpDown = (event, name) => {
    const showMessage = showCapsLockWarning(event, name);

    if (showMessage) setCapsLockMessage(t('form.fields.password.caps_lock_message'));
    else setCapsLockMessage(null);
  };

  const handleLogin = useCallback(async (data, options) => {
    setDisplayRecoverPasswordLink(false);

    const {
      authorizationHeader,
      loginThroughGoogle
    } = options || {};

    const {
      error: responseError,
      payload
    } = await dispatch(login({ ...data, authorizationHeader }));

    setLoginError(null);

    if (responseError) {
      const {
        message,
        status
      } = payload || {};

      if (authenticationTokenFromQueryString) router.replace('/login', null, { shallow: true });

      try {
        if (message === 'Invalid account or account requires password') throw new Error(t('google.invalid_account'));
        if (message === 'Invalid email or password') throw new Error(t('form.error'));

        if (status === 401) throw new Error(t('invalid_token'));

        setDisplayRecoverPasswordLink(true);
        throw new Error(t('form.error'));
      } catch (error) {
        if (loginThroughGoogle || authorizationHeader) showToast(error.message, 'error');
        else setLoginError(error.message);
      }
    } else {
      reset();

      router.push('/welcome');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, reset, router]);

  const handleFormSubmit = async (data) => {
    const {
      email,
      password
    } = data;

    handleLogin({ email, password });
  };

  const handleGoogleLoginError = () => {
    showToast(t('google.error'), 'error');
  };

  const handleGoogleLoginSuccess = (data) => {
    const { access_token: googleAccessToken } = data || {};

    if (googleAccessToken) handleLogin({ googleAccessToken }, { loginThroughGoogle: true });
  };

  const handleGoogleLogin = useGoogleLogin({
    onError: handleGoogleLoginError,
    onSuccess: handleGoogleLoginSuccess
  });

  useEffect(() => {
    if (authenticationTokenFromQueryString) {
      if (storedToken) {
        dispatch(logoutUser());
      }

      handleLogin(null, { authorizationHeader: { Authorization: `Bearer: ${authenticationTokenFromQueryString}` } });
    }
  }, [authenticationTokenFromQueryString, dispatch, handleLogin, storedToken]);

  useEffect(() => {
    setFocus('email');
  }, [setFocus]);

  const passwordInputIcon = (
    <Button
      disabled={isLoggingIn}
      icon={inputType === 'password' ? 'eye-closed' : 'eye'}
      noBackground
      paddingSize="none"
      theme="secondary"
      onClick={handleInputIconClick}
    />
  );

  return (
    <div className={`${styles.loginForm__wrapper}${className ? ` ${className}` : ''}${isLoggingIn ? ` ${styles.loginForm__wrapper_submitting}` : ''}`}>
      <h3 className={styles.loginForm__title}>
        {t('form.title')}
      </h3>
      <form
        className={styles.loginForm}
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, onBlur, ref, value } }) => (
            <Input
              ref={ref}
              autoComplete="email"
              className={styles.loginForm__input}
              description={errors.email?.message}
              disabled={isLoggingIn}
              id="email"
              label={t('form.fields.email.label')}
              placeholder={t('form.fields.email.placeholder')}
              validationState={errors.email?.message ? 'error' : null}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
            />
          )}
          rules={{
            pattern: {
              message: t('form.fields.email.error'),
              value: EMAIL_PATTERN
            },
            required: t('form.fields.email.error')
          }}
        />
        <Controller
          control={control}
          name="password"
          render={({ field: { onChange, onBlur, name, ref, value } }) => (
            <Input
              ref={ref}
              autoComplete="password"
              className={styles.loginForm__input}
              description={errors.password?.message}
              disabled={isLoggingIn}
              id="password"
              label={t('form.fields.password.label')}
              placeholder={t('form.fields.password.placeholder')}
              rightSlot={passwordInputIcon}
              type={inputType}
              validationState={errors.password?.message ? 'error' : null}
              value={value}
              warningMessage={capsLockMessage}
              onBlur={onBlur}
              onChange={onChange}
              onKeyDown={event => handleInputKeyUpDown(event, name)}
              onKeyUp={event => handleInputKeyUpDown(event, name)}
            />
          )}
          rules={{
            required: t('form.fields.password.error')
          }}
        />
        {/* This is not doing anything at the moment, so it is commented */}
        {/* <Controller
          control={control}
          name="checkbox"
          render={({ field: { onChange, onBlur, value } }) => (
            <Checkbox
              checked={value}
              className={styles.loginForm__checkbox}
              disabled={isLoggingIn}
              id="login-checkbox"
              label={t('form.fields.checkbox.label')}
              onBlur={onBlur}
              onCheckedChange={onChange}
            />
          )}
        /> */}
        <div className={styles.loginForm__loaderAndSubmitButton}>
          <div className={`${styles.loginForm__loaderWrapper}${isLoggingIn ? ` ${styles.loginForm__loaderWrapper_visible}` : ''}`}>
            <div className={styles.loginForm__loader}>
              <Loader
                color="white"
                direction="anti-clockwise"
                icon="loader"
              />
            </div>
          </div>
          {loginError && !isLoggingIn && (
            <ErrorBox
              className={styles.loginForm__errorBox}
              link={displayRecoverPasswordLink ?
                {
                  href: '/request-password-reset',
                  text: t('form.error_link')
                } :
                null}
              message={`${loginError} `}
            />
          )}
          <Button
            className={`${styles.loginForm__submitButton}${isLoggingIn ? ` ${styles.loginForm__submitButton_hidden}` : ''}`}
            text={t('form.submit_button')}
            type="submit"
          />
        </div>
      </form>
      <div className={`${styles.loginForm__loginAlternatives}${isLoggingIn ? ` ${styles.loginForm__loginAlternatives_hidden}` : ''}`}>
        <Button
          className={styles.loginForm__forgotPasswordLink}
          href="/request-password-reset"
          noBackground
          paddingSize="none"
          text={t('forgot_password.label')}
          textWeight="regular"
          theme="secondary"
          underlineOnHover
        />
        <div className={styles.loginForm__divider}>
          <hr className={styles.loginForm__dividerLine} />
          <span className={styles.loginForm__dividerText}>
            {t('or')}
          </span>
          <hr className={styles.loginForm__dividerLine} />
        </div>
        <Button
          className={styles.loginForm__googleButton}
          icon="google"
          iconPosition="left"
          text={t('google.button')}
          textWeight="regular"
          theme="google"
          onClick={handleGoogleLogin}
        />
        <div className={styles.loginForm__createAccountText}>
          <span>
            {t('create_account.text')}
          </span>
          <Button
            className={styles.loginForm__createAccountLink}
            href="/register"
            noBackground
            paddingSize="none"
            text={t('create_account.link.label')}
            textWeight="regular"
            theme="primary"
            underlineOnHover
          />
        </div>
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  className: PropTypes.string
};

LoginForm.defaultProps = {
  className: null
};


export default LoginForm;
