const showCapsLockWarning = (event, name) => {
  // Show warning when Caps Lock key is pressed (keycode 20)
  if (name === 'password' && event.keyCode === 20) {
    if (event.getModifierState('CapsLock')) return true;

    return false;
  }

  return null;
};

export default showCapsLockWarning;
