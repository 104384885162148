import React from 'react';
import PropTypes from 'prop-types';
import useTranslationWithFallback from 'hooks/use-translation-with-fallback';
import Link from 'next/link';

// Utils
import bemify from 'utils/bemify';

// Components
import Image from 'components/image';

// Styles
import stylesModule from './styles.module.scss';


const styles = bemify(stylesModule);

const Authentication = ({ leftContent, leftContentClassName, leftContentIllustrations, rightContent, rightContentClassName }) => {
  const { t } = useTranslationWithFallback('common');

  return (
    <section className={styles.authentication}>
      <div className={styles.authentication__leftContentWrapper}>
        {!!leftContentIllustrations && leftContentIllustrations}
        <div className={styles.authentication__leftContent}>
          <Link
            className={styles.authentication__logo}
            href="/"
            passHref
          >
            <Image
              alt={t('website_logo.alt')}
              height={32}
              src="/logos/resty.svg"
              width={114}
            />
          </Link>
          <div className={`${styles.authentication__leftContentWrapper_centered}${leftContentClassName ? ` ${leftContentClassName}` : ''}`}>
            {leftContent}
          </div>
        </div>
      </div>
      <div className={`${styles.authentication__rightContent}${rightContentClassName ? ` ${rightContentClassName}` : ''} d-none d-md-flex`}>
        {rightContent}
      </div>
    </section>
  );
};

Authentication.propTypes = {
  leftContent: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  leftContentClassName: PropTypes.string,
  leftContentIllustrations: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  rightContent: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  rightContentClassName: PropTypes.string
};

Authentication.defaultProps = {
  leftContent: null,
  leftContentClassName: null,
  leftContentIllustrations: null,
  rightContent: null,
  rightContentClassName: null
};

export default Authentication;
