
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import React, { useEffect } from 'react';

// Hooks
import useTranslationWithFallback from 'hooks/use-translation-with-fallback';

// Store
import { useDispatch, useSelector } from 'react-redux';
import { disableDisplaySessionExpiredMessage, selectDisplaySessionExpiredMessage } from 'store/slices/userSlice';

// Context
import { useToastContext } from 'context/toast-context';

// Utils
import bemify from 'utils/bemify';

// Components
import Image from 'components/image';
import LoginForm from 'components/login-form';

// Templates
import Authentication from 'templates/authentication';

// Images
import platformImage from 'assets/images/login/desktop/platform.png';

// SVG Images
import Circle1 from 'assets/images/login/desktop/circle-1.svg';
import Circle1Mobile from 'assets/images/login/mobile/circle-1.svg';
import Circle2 from 'assets/images/login/desktop/circle-2.svg';
import Circle3 from 'assets/images/login/desktop/circle-3.svg';
import Circle4 from 'assets/images/login/desktop/circle-4.svg';
import Circle5 from 'assets/images/login/desktop/circle-5.svg';
import Circle6 from 'assets/images/login/desktop/circle-6.svg';
import Triangle from 'assets/images/login/desktop/triangle.svg';

// Styles
import stylesModule from 'styles/pages/login.module.scss';


const styles = bemify(stylesModule);

const Login = () => {
  const { t } = useTranslationWithFallback('login');

  const dispatch = useDispatch();

  const { showToast } = useToastContext();

  // Selectors
  const displaySessionExpiredMessage = useSelector(selectDisplaySessionExpiredMessage);

  const leftContent = (
    <LoginForm />
  );

  const leftContentIllustrations = (
    <>
      <Circle5 className={`${styles.login__circle5} d-none d-md-block`} />
      <Circle6 className={`${styles.login__circle6} d-none d-md-block`} />
    </>
  );

  const rightContent = (
    <>
      <div className="d-none d-md-block">
        <Circle1 className={styles.login__circle1} />
        <Circle2 className={styles.login__circle2} />
        <Circle3 className={styles.login__circle3} />
        <Circle4 className={styles.login__circle4} />
        <Triangle className={styles.login__triangle} />
        <div className={styles.login__platformImage}>
          <Image
            alt="Platform"
            layout="fill"
            objectFit="cover"
            objectPosition="left"
            src={platformImage}
          />
        </div>
      </div>
      <div className="d-md-none">
        <Circle1Mobile />
        <div className={styles.login__platformImage}>
          <Image
            alt="Platform"
            layout="fill"
            objectFit="contain"
            objectPosition="left"
            src={platformImage}
          />
        </div>
      </div>
    </>
  );

  useEffect(() => {
    if (displaySessionExpiredMessage) {
      // We need to use setTimeout to ensure that the toast is shown
      setTimeout(() => {
        showToast(t('session_expired'), 'error');
      }, 0);

      dispatch(disableDisplaySessionExpiredMessage());
    }
  }, [dispatch, displaySessionExpiredMessage, showToast, t]);

  return (
    <Authentication
      leftContent={leftContent}
      leftContentIllustrations={leftContentIllustrations}
      rightContent={rightContent}
    />
  );
};

export default Login;


    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/login',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              
            }))
          }
        }
    }
  